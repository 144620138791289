import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <span role="img" aria-label="oportunitat">
                        🎯
                    </span>{" "}
                    <strong>
                        Aquest any, els alumnes d'iOpos Acadèmia tenen una oportunitat única!
                    </strong>
                </p>

                <p>
                    <span role="img" aria-label="test">
                        📝
                    </span>{" "}
                    Se'ls ofereix la possibilitat de realitzar el{" "}
                    <strong>TEST OFICIAL PSICOPROFESSIONAL</strong> de manera{" "}
                    <strong>presencial</strong>.
                </p>

                <p>
                    <span role="img" aria-label="temps">
                        ⏳
                    </span>{" "}
                    Durant <strong>50 minuts</strong>, podràs realitzar el test i, en un termini de{" "}
                    <strong>48-72 hores</strong>, rebràs al teu correu{" "}
                    <strong>una gràfica personalitzada</strong> amb l'
                    <strong>informe de les teves competències</strong>.{" "}
                    <span role="img" aria-label="estadística">
                        📊
                    </span>
                    <span role="img" aria-label="correo">
                        📩
                    </span>
                </p>

                <p>
                    <span role="img" aria-label="estrella">
                        ✨
                    </span>{" "}
                    <strong>
                        Aprofita aquesta oportunitat per conèixer millor les teves habilitats i
                        millorar la teva preparació!
                    </strong>{" "}
                    <span role="img" aria-label="cohete">
                        🚀
                    </span>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="🔥 El curs que et portarà directe a la teva plaça als Mossos d'Esquadra! 🔥"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
